import React from "react"
// import Navigation from "./navigation"
import Footer from "./footer"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import faviconNew from "../images/favicon.ico"

const LocaleContext = React.createContext()

// Use the built-in Context API to make the "locale" available to every component in the tree
// This e.g. enables the LocalizedLink to function correctly
// As this component wraps every page (due to the wrapPageElement API) we can be sure to have
// the locale available everywhere!
const Layout = ({ children, pageContext: { locale, unlocalizedPath, pageName, meta} }) => {

  const { data } = useStaticQuery(query)
  const siteUrl = data.siteMetadata.siteUrl
  let localeStr = locale.replace('_','-')
  const ogUrl = (locale === 'en') ? siteUrl+unlocalizedPath : `${siteUrl}/${localeStr}${unlocalizedPath}`
  const description = stripHTML(meta.description)
  const socialImg = siteUrl + '/social/rainberry-og.jpg'

  return (
  <LocaleContext.Provider value={{ locale }}>
    <Helmet>
          <title>{meta.title}</title>
          <meta http-equiv="content-type" content="text/html; charset=UTF-8"/>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=0, minimum-scale=1.0, maximum-scale=1.0"/>
          <meta name="description" content={description}/>
          <meta name="keywords" content={meta.keywords}/>
          <meta name="author" content="BitTorrent, Inc."/>
          <meta property="og:url" content={ogUrl}/>
          <meta property="og:type" content="website"/>
          <meta property="og:title" content={meta.title}/>
          <meta property="og:description" content={description}/>
          <meta property="og:image" content={socialImg}/>
          <meta property="og:site_name" content="Rainberry"/>
          <meta name="twitter:site" content="@BitTorrent" />
          <meta name="twitter:image:src" content={socialImg} />
          <meta name="twitter:card" content="summary_large_image"/>
          <meta http-equiv="content-language" content={ locale }/>
          <link href={faviconNew} rel="shortcut icon"/>
          <link rel="alternate" hreflang="en" href={`${siteUrl}${unlocalizedPath}`} />
          <link rel="alternate" hreflang="x-default" href={`${siteUrl}${unlocalizedPath}`} />
          
          <link rel="canonical" href={ogUrl} />
          <link href="/fonts/Inter/inter.css" rel="stylesheet"/>
    </Helmet>
    <div className="container-fluid px-0 mx-0">
        <header className="global-header">
          {/* <Navigation/> */}
        </header>

        <main role="main" className={`w-100 d-block ${pageName}`}>
              {/* {children} */}
        </main>
        <Footer/>
    </div>
  </LocaleContext.Provider>
)}


export { Layout, LocaleContext }

const query = graphql`
query layout {
    data: site {
        siteMetadata {
          siteUrl
        }
      }
  }
`

function stripHTML(string){
  return string.replace(/<(.|\n)*?>/g, '');
}

