import React from 'react';
import { Link } from "gatsby"
import logo from '../images/rainberry-logo-blue-icon.svg'

const Footer = () => {

  return (    
     <>
        <footer className="container-fluid min-vh-100 py-5">
        	<div className="container">
        		<div>
        			<a href="/" className="d-block" id="footer-logo">
			          <img src={logo} alt="Rainberry Logo" className="rainberry-logo"/>
			        </a>
        		</div>
        		<div className="d-flex my-5 flex-wrap">
        			<div className="px-0 my-3 col-12 col-md-6 col-lg-4 pr-0 pr-md-3 pr-lg-3 order-lg-1">
        				<p className="lg-text">
        					58 West Portal Ave, #552 <br/> San Francisco, California 94127
        				</p>
        				<p>
        					Please note, due to the pandemic, our mailing address has changed.
        				</p>
        			</div>
        			<div className="px-0 my-3 col-12 col-md-6 col-lg-4 pl-0 pl-md-3 pl-lg-0 pr-lg-3 order-lg-4">
        				<p className="lg-text">
        					+1-628-253-1020
        				</p>
        				<p>
        					Please note that this phone number does not service customer support requests.
        				</p>
        			</div>
        			<div className="px-0 my-3 col-12 col-md-6 col-lg-5 pr-0 pr-md-3 pr-lg-0 pl-lg-3 order-lg-2">
        				<p className="lg-text">
        					Interested in contacting us?
        				</p>
        				<p>
        					Please use the contact methods below to route your email to the appropriate person.
        				</p>
        			</div>
        			<div className="px-0 my-3 col-12 col-md-6 col-lg-5 pl-0 pl-md-3 pl-lg-3 order-lg-5">
        				<p className="lg-text contact-emails">
							<span className="bold-text">Legal</span> - legal (at) rainberry (dot) com
        				</p>
        			</div>
        		</div>
		        <hr></hr>
		        <div className="d-flex flex-column flex-lg-row justify-content-between xs-text">
					<div className="px-0 col-12 col-lg-6 text-center text-lg-left">© {new Date().getFullYear()} Rainberry, Inc. All Rights Reserved.</div>
		        </div>
        	</div>
        </footer>
      </>
  )
}

export default Footer

